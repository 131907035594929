import Particle from "./modules/Particle";
// import image from "../data/image.png";
import logoWhite from '../data/last-logo_white.png';
// import logoBlack from '../data/last-logo.png';

const myImage = new Image();
myImage.src = logoWhite;

function process(ctx, width, height) {
    const particlesArray = [];
    const numberOfParticles = 1000;
    const pixels = ctx.getImageData(0, 0, width, height);
    const mappedImage = [];

    ctx.clearRect(0, 0, width, height);

    mapParticles(pixels, mappedImage, width, height);
    init(numberOfParticles, particlesArray, mappedImage, ctx, width, height);
    animate(particlesArray, ctx, width, height);
}

function init(numberOfParticles, particlesArray, mappedImage, ctx, width, height) {
    for(let i = 0; i < numberOfParticles; i++) {
        particlesArray.push(new Particle(ctx, mappedImage, width, height));
    }
}

function animate(particlesArray, ctx, width, height) {
    ctx.globalAlpha = 0.05;
    ctx.fillStyle = 'rgb(0, 0, 0)';
    ctx.fillRect(0, 0, width, height);
    ctx.globalAlpha = 0.2;
    
    particlesArray.forEach(item => {
        item.update();
        // ctx.globalAlpha = item.speed * 0.5;
        item.draw();
    });

    requestAnimationFrame(() => animate(particlesArray, ctx, width, height));
}

function mapParticles(pixels, mappedImage, width, height) {
    for(let y = 0; y < height; y++) {
        let row = [];

        for(let x = 0; x < width; x++) {
            const red = pixels.data[(y * 4 * pixels.width) + (x * 4)];
            const green = pixels.data[(y * 4 * pixels.width) + (x * 4 + 1)];
            const blue = pixels.data[(y * 4 * pixels.width) + (x * 4 + 2)];
            const brightness = calculateRelativeBrightness(red, green, blue);
            const cellColor = `rgb(${red},${green},${blue})`;
            row.push([
                brightness,
                cellColor,
            ]);
        }

        mappedImage.push(row);
    }
}

function calculateRelativeBrightness(red, green, blue) {
    return Math.sqrt(
        (red * red) * 0.299 +
        (green * green) * 0.587 + 
        (blue * blue) * 0.114
    ) / 100;
}

myImage.addEventListener("load", () => {
  const canvas = document.getElementById("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = 500;
  canvas.height = 276;

  ctx.drawImage(myImage, 0, 0, canvas.width, canvas.height);

  process(ctx, canvas.width, canvas.height);
});
