class Particle {
    constructor(ctx, mappedImage, width, heigth) {
        this.ctx = ctx;
        this.width = width;
        this.heigth = heigth;
        this.mappedImage = mappedImage;

        this.x = Math.random() * width;
        this.y = 0;
        this.speed = 0;
        this.velocity = Math.random() * 0.5;
        this.size = Math.random() * 1.5 + 1;
        this.position1 = Math.floor(this.y);
        this.position2 = Math.floor(this.x);
    }

    update() {
        this.position1 = Math.floor(this.y);
        this.position2 = Math.floor(this.x);
        this.speed = this.mappedImage[this.position1][this.position2][0];

        this.y += (2.5 - this.speed) + this.velocity;

        if (this.y >= this.heigth) {
            this.y = 0;
            this.x = Math.random() * this.width;
        }
    }

    draw() {
        this.ctx.beginPath();
        this.ctx.fillStyle = this.mappedImage[this.position1][this.position2][1];
        this.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        this.ctx.fill();
    }
}

export default Particle;